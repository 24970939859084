import { onBeforeMount, ref, computed } from "@vue/composition-api";
import { getEventTypes } from "@/services/event-type/get-event-types";
import { getZones } from "@/services/zone/get-zones";
export function useFilters() {
    const typesData = useEventTypes();
    const zonesData = useEventZones();
    const datesData = useDates();
    return {
        filtersDownloading: computed(() => getEventTypes.active.value && getZones.active.value),
        eventFilters: computed(() => ({
            zone: zonesData.selectedZoneId.value,
            type: typesData.selectedTypeId.value,
            startDate: datesData.startDate.value,
            endDate: datesData.endDate.value,
        })),
        ...typesData,
        ...zonesData,
        ...datesData,
    };
}
function useEventTypes() {
    const selectedTypeId = ref(0);
    const typesData = ref([]);
    const types = computed(() => typesData.value.map(t => ({
        title: t.name,
        value: t.id,
    })));
    const selectTypeId = (typeId) => {
        selectedTypeId.value = typeId;
    };
    onBeforeMount(async () => {
        typesData.value = await getEventTypes.run();
    });
    return {
        types,
        selectedTypeId,
        selectTypeId,
    };
}
function useEventZones() {
    const selectedZoneId = ref(0);
    const zonesData = ref([]);
    const zones = computed(() => zonesData.value.map(z => ({
        title: z.name,
        value: z.id,
    })));
    const selectZoneId = (zoneId) => {
        selectedZoneId.value = zoneId;
    };
    onBeforeMount(async () => {
        zonesData.value = await getZones.run();
    });
    return {
        zones,
        selectedZoneId,
        selectZoneId,
    };
}
function useDates() {
    const startDate = ref(null);
    const endDate = ref(null);
    const setDate = (type, value) => {
        if (type === "start")
            startDate.value = value;
        if (type === "end")
            endDate.value = value;
    };
    return {
        startDate: computed(() => startDate.value),
        endDate: computed(() => endDate.value),
        setDate,
    };
}
