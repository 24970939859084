import { computed, ref } from "@vue/composition-api";
export function useSelectedEvent({ setCode, clearCode, }) {
    const event = ref(null);
    const eventId = ref(0);
    const eventIsClosed = computed(() => event.value !== null && event.value.status === "closed");
    const selectEvent = (selectedEvent) => {
        event.value = selectedEvent;
        eventId.value = selectedEvent.id;
        setEventDownloaded(true);
        setCode(selectedEvent.type.code);
    };
    const clearEvent = () => {
        event.value = null;
        eventId.value = 0;
        setEventDownloaded(false);
        clearCode();
    };
    const eventDownloaded = ref(false);
    const setEventDownloaded = (status) => {
        eventDownloaded.value = status;
    };
    return {
        eventId: computed(() => eventId.value),
        eventDownloaded: computed(() => eventDownloaded.value),
        eventIsClosed,
        selectEvent,
        clearEvent,
    };
}
