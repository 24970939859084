import { useEditingEventFields } from "@events/composable/event-fields";
export function useVisibleFields() {
    const visibleFields = [
        "name",
        "type",
        "zone",
        "coordinate",
        "creationTime",
        "creator",
        "victims",
        "owners",
        "leaders",
        "description",
        "sequence",
        "involvedRescuers",
        "involvedVehicles",
    ];
    const { eventFields, setCode, clearCode } = useEditingEventFields({ visibleFields });
    return {
        visibleFields: eventFields,
        setCode,
        clearCode,
    };
}
