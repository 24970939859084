export function printHTML(html) {
    const win = window.open("", "_blank");
    if (win === null)
        throw new Error("WINDOW IS NULL");
    win.document.write(`
    <html>
      <body>
        ${html}
      </body>
    </html>
  `);
    win.document.close();
    setTimeout(() => {
        win.focus();
        win.print();
        win.close();
    }, 1000);
}
