import { defineComponent } from "@vue/composition-api";
import EventsByFilters from "@events/components/EventsByFilters.vue";
import EventReport from "@events/components/EventReport/index.vue";
import { downloadEventReportAsFile } from "@/services/event-report/download-event-report-as-file";
import { useFilters } from "./filters";
import { useIsChildView } from "./is-child-view";
import { useVisibleFields } from "./visible-fields";
import { useSelectedEvent } from "./selected-event";
export default defineComponent({
    name: "ClosedEvents",
    components: {
        EventsByFilters,
        EventReport,
    },
    setup() {
        const { filtersDownloading, types, selectedTypeId, selectTypeId, zones, selectedZoneId, selectZoneId, startDate, endDate, setDate, eventFilters, } = useFilters();
        const { isChildView } = useIsChildView();
        const { visibleFields, setCode, clearCode } = useVisibleFields();
        const { eventId, eventDownloaded, eventIsClosed, selectEvent, clearEvent, } = useSelectedEvent({ setCode, clearCode });
        const downloadReport = async () => {
            await downloadEventReportAsFile.run(eventId.value);
        };
        return {
            filtersDownloading,
            types,
            selectedTypeId,
            selectTypeId,
            zones,
            selectedZoneId,
            selectZoneId,
            startDate,
            endDate,
            setDate,
            eventFilters,
            isChildView,
            visibleFields,
            eventId,
            eventDownloaded,
            eventIsClosed,
            selectEvent,
            clearEvent,
            downloadReport,
        };
    },
});
