import { computed, defineComponent, onBeforeMount, ref } from "@vue/composition-api";
import { getEventReport } from "@/services/event-report/get-event-report";
import { printHTML } from "@/utils/print";
export default defineComponent({
    name: "EventReport",
    props: {
        eventId: {
            type: Number,
            required: true,
        },
    },
    setup(props) {
        const sections = ref([]);
        const reportHTML = computed(() => sections.value.map(s => s.html).join(""));
        const downloadEventReport = async () => {
            sections.value = await getEventReport.run(props.eventId);
        };
        const printReport = async () => {
            await printHTML(reportHTML.value);
        };
        onBeforeMount(downloadEventReport);
        return {
            reportDownloading: getEventReport.active,
            sections,
            reportHTML,
            printReport,
        };
    },
});
